import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';
import { COMPANY_NAME, ROUTES } from 'src/config';
import useAuthPersistStore, {
  AuthStoreType
} from 'src/hooks/store/useAuthPersistStore';
import ExternalLayout from 'src/layouts/Layout/ExternalLayout';
import InternalLayout from 'src/layouts/Layout/InternalLayout';
import getIntercom from 'src/utils/intercom';
import Initializer from '../Initializer';
import { Helmet } from 'react-helmet-async';

export type LayoutProps = {
  children?: ReactNode;
  title: string;
  getSubnav?: (() => ReactNode) | null;
  publicPage?: boolean;
  includePadding?: boolean;
  verificationNotRequired?: boolean;
  disableLayoutElements?: boolean;
};

export function RedirectView() {
  const { authState, isSigningOut, setIsSigningOut } =
    useAuthPersistStore((store: AuthStoreType) => ({
      authState: store.authState,
      isSigningOut: store.isSigningOut,
      setIsSigningOut: store.setIsSigningOut
    }));
  const router = useRouter();
  useEffect(() => {
    if (isSigningOut) {
      setIsSigningOut(false);
    } else {
      if (!authState.isAuthenticated) {
        router.push(ROUTES.AUTH.SIGNIN);
      } else {
        if (authState.isAuthenticated && !authState.emailVerified) {
          router.push(ROUTES.AUTH.VERIFY);
        } else {
          router.push(ROUTES.AUTH.SIGNIN);
        }
      }
    }
  }, []);
  return <></>;
}

// -----------------------------------------------------------------------------------------

export default function Layout(props: LayoutProps) {
  const { authState } = useAuthPersistStore(
    (store: AuthStoreType) => ({
      authState: store.authState
    })
  );

  const userId = authState.isAuthenticated ? authState.userId : null;
  useEffect(() => {
    getIntercom()?.boot({ userId });
  }, []);

  const isFullyAuthenticated =
    authState.isAuthenticated &&
    (props.verificationNotRequired ? true : authState.emailVerified);

  const pageAccessBlocked =
    !isFullyAuthenticated && !props.publicPage;

  return pageAccessBlocked ? (
    // Unauthenticated user is trying to access a page that is not public
    <RedirectView />
  ) : (
    // Page is public, or user is fully authenticated
    <Initializer>
      <div className="h-screen w-screen">
        <Helmet>
          <title>
            {props.title} | {COMPANY_NAME}
          </title>
        </Helmet>
        {isFullyAuthenticated && !props.publicPage ? (
          <InternalLayout {...props} />
        ) : (
          <ExternalLayout {...props} />
        )}
      </div>
    </Initializer>
  );
}

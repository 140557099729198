import { PaymentElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import Input from 'src/components/core/atoms/Input';
import CountryCodeSingleSelect from 'src/views/BillingView/CountryCodeSingleSelect';

// ----------------------------------------------------------------------

export default function PaymentForm({
  postalCodeState,
  countryCodeState,
  setIsComplete
}: {
  setIsComplete: (isComplete: boolean) => void;
  postalCodeState: [
    string | null,
    (postalCode: string | null) => void
  ];
  countryCodeState: [string, (countryCode: string) => void];
}) {
  const [postalCode, setPostalCode] = postalCodeState;
  const [countryCode, setCountryCode] = countryCodeState;

  const [editValues, setEditValues] = useState({
    postalCode,
    countryCode
  });

  return (
    <div className="flex flex-col flex-nowrap">
      {/* Country/Postal Code */}
      <div className="my-3 w-full">
        <div className="flex flex-nowrap items-center">
          <div className="mr-sm w-1/2">
            <CountryCodeSingleSelect
              value={countryCode}
              setValue={setCountryCode}
            />
          </div>
          <div className="flex w-1/2 flex-col flex-nowrap">
            <p className="mb-sm">Postal code</p>
            <Input
              required
              value={editValues.postalCode}
              placeholder="A1B 2C3"
              onChange={(e) => {
                setPostalCode(null);
                setEditValues({
                  ...editValues,
                  postalCode: e.target.value
                });
              }}
              onBlur={() => {
                if (editValues.postalCode !== postalCode) {
                  setPostalCode(editValues.postalCode);
                }
              }}
            />
          </div>
        </div>
      </div>
      {/* Payment form */}
      <PaymentElement
        onChange={(e) => {
          setIsComplete(e.complete);
        }}
        options={{
          // Omit Stripe postal code and country code elements so that we have access to the values
          fields: {
            billingDetails: {
              address: {
                postalCode: 'never',
                country: 'never'
              }
            }
          },
          // Pass in values for postal code and country code
          defaultValues: {
            billingDetails: {
              address: {
                postal_code: postalCode,
                country: countryCode
              }
            }
          }
        }}
      />
    </div>
  );
}

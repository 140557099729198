import { ButtonType } from 'src/@types/button';
import { MaxWidthType } from 'src/@types/dimensions';
import useDevice from 'src/hooks/useDevice';
import DefaultDesktopTopbar from 'src/layouts/Topbar/DefaultDesktopTopbar';
import DefaultMobileTopbar from 'src/layouts/Topbar/DefaultMobileTopbar';

export default function Topbar({
  includeSearch,
  mainButtons = [],
  actionButtons = [],
  maxWidth
}: {
  includeSearch?: boolean;
  mainButtons?: {
    component: ButtonType;
    dropdown: ButtonType[];
  }[];
  actionButtons?: ButtonType[];
  maxWidth?: MaxWidthType;
}) {
  const { isMobile } = useDevice();
  return (
    <div className="h-topbar w-full">
      {isMobile ? (
        <DefaultMobileTopbar
          mainButtons={mainButtons}
          actionButtons={actionButtons}
        />
      ) : (
        <DefaultDesktopTopbar
          includeSearch={includeSearch}
          mainButtons={mainButtons}
          actionButtons={actionButtons}
          maxWidth={maxWidth}
        />
      )}
    </div>
  );
}

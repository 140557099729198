import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import {
  InvoicePreviewQueryQuery,
  SubscriptionFrequenciesEnum
} from 'src/__apolloGenerated__/graphql';
import Skeleton from 'src/components/core/atoms/Skeleton';
import { SubscriptionPlanType } from 'src/components/core/organisms/dialogs/SubscribeDialog';
import { fPrecision, getListItemKey } from 'src/utils/format';

export const formatCurrency = (amount: string) => {
  if (amount?.[0] === '-') {
    return `- $${fPrecision(Number(amount.slice(1) || 0), 2)} USD`;
  } else {
    return `$${fPrecision(Number(amount || 0), 2)} USD`;
  }
};

export default function InvoicePreview({
  promotionCode,
  newPlan,
  postalCode,
  countryCode,
  billingFrequency,
  subscriptionError,
  setInvalidPromotionCode
}: {
  promotionCode: string;
  newPlan: SubscriptionPlanType;
  billingFrequency: SubscriptionFrequenciesEnum;
  subscriptionError: string;
  postalCode?: string;
  countryCode?: string;
  setInvalidPromotionCode: (value: boolean) => void;
}) {
  const { data: invoicePreviewData, loading } =
    useQuery<InvoicePreviewQueryQuery>(
      gql`
        query InvoicePreviewQuery(
          $planTier: SubscriptionPlanTiersEnum!
          $planFrequency: SubscriptionFrequenciesEnum!
          $countryCode: CountryCodesEnum
          $postalCode: String
          $promotionCode: String
        ) {
          invoicePreview(
            planTier: $planTier
            planFrequency: $planFrequency
            countryCode: $countryCode
            postalCode: $postalCode
            promotionCode: $promotionCode
          ) {
            data {
              lineItems {
                amount
                description
                planTier
                billingInterval
              }
              subtotal
              totalExcludingTax
              tax
              total
              discount {
                name
                amountOff
                percentOff
              }
            }
          }
        }
      `,
      {
        variables: {
          planTier: newPlan.tier,
          planFrequency: billingFrequency,
          countryCode: countryCode ? countryCode : undefined,
          postalCode: postalCode ? postalCode : undefined,
          promotionCode:
            promotionCode === '' ? undefined : promotionCode
        },
        fetchPolicy: 'network-only'
      }
    );

  const invoicePreview = invoicePreviewData?.invoicePreview.data;

  useEffect(() => {
    if (
      promotionCode !== '' &&
      promotionCode !== null &&
      promotionCode !== undefined &&
      !invoicePreview?.discount &&
      !loading
    ) {
      setInvalidPromotionCode(true);
    } else {
      setInvalidPromotionCode(false);
    }
  }, [promotionCode, invoicePreview]);

  return (
    <div className="flex flex-col flex-nowrap">
      {/* Description and amount */}
      {loading ? (
        <div className="">
          <Skeleton height={80} />
        </div>
      ) : (
        <div className="w-full">
          <div className="flex flex-col flex-nowrap">
            <div className="mb-[-1px] flex h-row items-center justify-between rounded-t-sm border px-sm">
              <p>Purchase Description</p>
              <p>Amount</p>
            </div>
            {invoicePreview ? (
              invoicePreview?.lineItems.map((lineItem, idx) => {
                return (
                  <div
                    key={getListItemKey(idx)}
                    className="mb-[-1px] flex h-row items-center border px-sm"
                  >
                    <div className="flex w-full flex-nowrap items-center justify-between">
                      <p>{lineItem.description}</p>
                      <p>{formatCurrency(lineItem.amount)}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="mb-[-1px] flex h-row items-center border px-sm">
                <div className="flex w-full flex-nowrap items-center justify-between">
                  <p>TBD</p>
                  <p>TBD</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Subtotal */}
      <div className="mt-lg rounded-md bg-card p-md">
        <div className="flex flex-col flex-nowrap">
          <div className="w-full">
            <div className="flex flex-nowrap items-center justify-between">
              <p>Subtotal:</p>
              {loading ? (
                <div>
                  <Skeleton height={25} />
                </div>
              ) : (
                <p
                  className={
                    Number(
                      invoicePreview?.discount?.percentOff || 0
                    ) > 0
                      ? 'text-success'
                      : ''
                  }
                >
                  {invoicePreview?.totalExcludingTax
                    ? formatCurrency(
                        invoicePreview?.totalExcludingTax
                      )
                    : 'TBD'}
                  {invoicePreview?.discount?.percentOff
                    ? ` (-${fPrecision(invoicePreview?.discount?.percentOff || 0)}%)`
                    : ''}
                </p>
              )}
            </div>
          </div>
          {/* Tax */}
          <div className="mt-sm w-full">
            <div className="flex flex-nowrap items-center justify-between">
              <p>Tax:</p>
              {loading ? (
                <div>
                  <Skeleton height={25} />
                </div>
              ) : (
                <p>
                  {invoicePreview?.tax
                    ? formatCurrency(invoicePreview?.tax)
                    : 'TBD'}
                </p>
              )}
            </div>
          </div>
          {/* Amount Due */}
          <div className="mt-lg w-full">
            <div className="flex flex-nowrap items-center justify-between">
              <p>Amount Due:</p>
              {loading ? (
                <div>
                  <Skeleton height={25} />
                </div>
              ) : (
                <p className="subtitle2 font-bold">
                  {invoicePreview?.total
                    ? formatCurrency(invoicePreview?.total)
                    : 'TBD'}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Subscription Error */}
      {subscriptionError && !loading && (
        <div className="mt-md">
          <p className="text-destructive">{subscriptionError}</p>
        </div>
      )}
    </div>
  );
}

import SingleSelect from 'src/components/core/molecules/SingleSelect';
import { COUNTRY_CODES } from 'src/config';

export default function CountryCodeSingleSelect({
  value,
  setValue,
  disableClear,
}: {
  value: string;
  setValue: (value: string) => void;
  disableClear?: boolean;
}) {
  return (
    <div className="flex flex-col flex-nowrap">
      <p className="mb-sm">Country </p>
      <SingleSelect
        value={value}
        setValue={setValue}
        size="default"
        disableClear={disableClear}
        options={Object.keys(COUNTRY_CODES).map((countryCode) => {
          return {
            key: COUNTRY_CODES[countryCode],
            label: countryCode
          };
        })}
      />
    </div>
  );
}

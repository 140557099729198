import {
  DataCalculatorTypesEnum
} from 'src/__apolloGenerated__/graphql';
import { AssetTypeType } from 'src/hooks/store/useAssetStore';
import { create } from 'zustand';

export type DataCalculatorStoreType = {
  activeAssetType: AssetTypeType;
  setActiveAssetType: (assetType: AssetTypeType) => void;
  activeAsset: any;
  // | LocationFragmentFragment
  // | VehicleFragmentFragment
  // | EquipmentFragmentFragment;
  setActiveAsset: (
    asset: any
    // | LocationFragmentFragment
    // | VehicleFragmentFragment
    // | EquipmentFragmentFragment
  ) => void;
  activeCalculatorType: DataCalculatorTypesEnum;
  setActiveCalculatorType: (
    calculatorType: DataCalculatorTypesEnum
  ) => void;
  initialMeasurement: any;
  setInitialMeasurement: (measurement: any) => void;
  clearCurrentActive: () => void;
};

const useDataCalculatorStore = create<DataCalculatorStoreType>(
  (set) => ({
    activeAssetType: null,
    setActiveAssetType: (assetType) =>
      set({ activeAssetType: assetType }),
    activeAsset: null,
    setActiveAsset: (asset) => set({ activeAsset: asset }),
    activeCalculatorType: null,
    setActiveCalculatorType: (calculatorType) =>
      set({ activeCalculatorType: calculatorType }),
    initialMeasurement: null,
    setInitialMeasurement: (measurement) =>
      set({ initialMeasurement: measurement }),
    clearCurrentActive: () => {
      set({
        activeAsset: null,
        activeCalculatorType: null,
        initialMeasurement: null,
        activeAssetType: null
      });
    }
  })
);

export default useDataCalculatorStore;

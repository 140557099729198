import { ReactNode } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'src/components/shad-base/popover';

export default function PopoverMenu({
  content,
  trigger
}: {
  content: ReactNode;
  trigger: ReactNode;
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent className="mt-sm flex">
        {content}
      </PopoverContent>
    </Popover>
  );
}

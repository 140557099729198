import { ButtonType } from 'src/@types/button';
import { ROUTES } from 'src/config';
import useAuthPersistStore from 'src/hooks/store/useAuthPersistStore';
import useDevice from 'src/hooks/useDevice';
import Footer from 'src/layouts/Footer';
import { LayoutProps } from 'src/layouts/Layout';
import Topbar from 'src/layouts/Topbar';
import { platformItems, resourceItems } from 'src/navigation';

// User may or may not be authenticated, and page is public
export default function ExternalLayout({
  children,
  getSubnav,
  includePadding = true
}: LayoutProps) {
  const { authState } = useAuthPersistStore();
  const { isMobile } = useDevice();

  // Login and signup buttons for Topbar
  const actionButtons: ButtonType[] = authState.isAuthenticated
    ? []
    : [
        {
          name: 'Log in',
          path: ROUTES.AUTH.SIGNIN,
          variation: 'tertiary'
        },
        {
          name: 'Sign up',
          path: ROUTES.AUTH.SIGNUP,
          variation: 'secondary'
        }
      ];

  if (authState.isAuthenticated) {
    actionButtons.unshift({
      name: 'Dashboard',
      path: ROUTES.DASHBOARD,
      variation: 'secondary'
    });
  }

  // Main topbar buttons
  const mainButtons: {
    component: ButtonType;
    dropdown: ButtonType[];
  }[] = [];

  // Mobile menu flattens out the product dropdowns into a single list (no need for products button)
  if (!isMobile) {
    mainButtons.push({
      component: {
        name: 'Products',
        path: ROUTES.ROOT,
        variation: 'tertiary'
      },
      dropdown: platformItems
    });
  }
  mainButtons.push(
    {
      component: {
        name: 'EmitAI',
        path: ROUTES.PRODUCTS.AI,
        variation: 'tertiary'
      },
      dropdown: []
    },
    {
      component: {
        name: 'Pricing',
        path: ROUTES.RESOURCES.PRICING,
        variation: 'tertiary'
      },
      dropdown: []
    }
  );
  if (!isMobile) {
    mainButtons.push({
      component: {
        name: 'Resources',
        path: ROUTES.ROOT,
        variation: 'tertiary'
      },
      dropdown: resourceItems
    });
  }
  return (
    <div className="flex h-full w-full flex-col flex-nowrap">
      {/* Topbar */}
      <div
        id="topbar"
        className="fixed top-0 z-topbar flex h-topbar w-full justify-center"
      >
        <div className="flex w-[1325px]">
          <Topbar
            mainButtons={mainButtons}
            actionButtons={actionButtons}
          />
        </div>
      </div>
      {/* Page Content */}
      <div className="h-page-height mt-topbar w-full overflow-y-auto">
        <div className="flex flex-col flex-nowrap">
          {/* Subnav */}
          {getSubnav && (
            <div className="fixed top-topbar z-subnav h-topbar w-full border-b border-t bg-background">
              <div className="flex h-full items-center px-md">
                {getSubnav()}
              </div>
            </div>
          )}
          {/* Page */}
          <div
            className={
              'max-w-screen w-full overflow-x-clip ' +
              (getSubnav && 'mt-topbar h-page-height-topbar-subnav')
            }
          >
            <div className="flex flex-col flex-nowrap">
              <div
                className={'w-full ' + (includePadding ? 'p-xl' : '')}
              >
                {children}
              </div>
              {/* Footer */}
              <div className="border-t p-lg">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

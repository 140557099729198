import { gql } from '@apollo/client';
import {
  ArchiveFailedPaymentsInput,
  ArchiveFailedPaymentsPayload,
  RestoreCancelledSubscriptionInput,
  RestoreCancelledSubscriptionPayload
} from 'src/__apolloGenerated__/graphql';
import useBackendMutation from 'src/hooks/useBackendMutation';

export type AlertTypeType =
  | 'failed-payment'
  | 'pending-activation'
  | 'pending-cancellation'
  | 'cancelled-and-valid-until'
  | 'on-paid-plan-but-no-payment-method';

export type AlertType = {
  type: AlertTypeType;
  color?: 'warning' | 'destructive';
  button?: { text: string; onClick: () => void; loading: boolean };
  onClear?: { callback: () => void; loading: boolean };
  restrictActions?: {
    updatePlan: boolean;
    editBillingFrequency: boolean;
    editPaymentMethod: boolean;
    cancelPlan: boolean;
  };
};

export default function useSubscriptionAlert(billingState: {
  failedPayment: boolean;
  pendingActivation: boolean;
  pendingCancellation: boolean;
  subscriptionIsCancelledAndValidUntil: boolean;
  onPaidPlanButNoPaymentMethod: boolean;
  activePlanName: string;
}) {
  const {
    failedPayment,
    pendingActivation,
    pendingCancellation,
    subscriptionIsCancelledAndValidUntil,
    onPaidPlanButNoPaymentMethod,
    activePlanName
  } = billingState;

  const restrictedActions = {
    'failed-payment': {
      updatePlan: false,
      editPaymentMethod: false,
      cancelPlan: false,
      editBillingFrequency: false
    },
    'pending-activation': {
      updatePlan: true,
      editPaymentMethod: true,
      cancelPlan: true,
      editBillingFrequency: true
    },
    'pending-cancellation': {
      updatePlan: true,
      editPaymentMethod: true,
      cancelPlan: true,
      editBillingFrequency: true
    },
    'cancelled-and-valid-until': {
      updatePlan: Boolean(onPaidPlanButNoPaymentMethod),
      editPaymentMethod: false,
      cancelPlan: true,
      editBillingFrequency: true
    },
    'on-paid-plan-but-no-payment-method': {
      updatePlan: true,
      editPaymentMethod: false,
      cancelPlan: false,
      editBillingFrequency: true
    }
  };

  const {
    mutate: restoreCancelledSubscription,
    loading: restoringCancelledSubscription
  } = useBackendMutation<
    { input: RestoreCancelledSubscriptionInput },
    RestoreCancelledSubscriptionPayload
  >({
    mutation: gql`
      mutation RestoreCancelledSubscriptionMutation(
        $input: RestoreCancelledSubscriptionInput!
      ) {
        restoreCancelledSubscription(input: $input) {
          user {
            ...UserFragment
          }
          errors {
            ...ErrorsFragment
          }
        }
      }
    `
  });

  const {
    mutate: archiveFailedPayments,
    loading: archivingFailedPayments
  } = useBackendMutation<
    { input: ArchiveFailedPaymentsInput },
    ArchiveFailedPaymentsPayload
  >({
    mutation: gql`
      mutation ArchiveFailedPaymentsMutation(
        $input: ArchiveFailedPaymentsInput!
      ) {
        archiveFailedPayments(input: $input) {
          user {
            ...UserFragment
          }
          errors {
            ...ErrorsFragment
          }
        }
      }
    `
  });

  let alert: AlertType = null;

  if (failedPayment) {
    alert = {
      type: 'failed-payment',
      color: 'destructive',
      onClear: {
        callback: () => {
          archiveFailedPayments({
            variables: {
              input: {
                obliterate: true
              }
            }
          });
        },
        loading: archivingFailedPayments
      },
      restrictActions: restrictedActions['failed-payment']
    };
  } else if (pendingActivation) {
    alert = {
      type: 'pending-activation',
      restrictActions: restrictedActions['pending-activation']
    };
  } else if (pendingCancellation) {
    alert = {
      type: 'pending-cancellation',
      restrictActions: restrictedActions['pending-cancellation']
    };
  } else if (subscriptionIsCancelledAndValidUntil) {
    alert = {
      type: 'cancelled-and-valid-until',
      button: {
        text: `Resubscribe to ${activePlanName}`,
        onClick: () => {
          restoreCancelledSubscription({
            variables: { input: { restore: true } }
          });
        },
        loading: restoringCancelledSubscription
      },
      restrictActions: restrictedActions['cancelled-and-valid-until']
    };
  } else if (onPaidPlanButNoPaymentMethod) {
    alert = {
      type: 'on-paid-plan-but-no-payment-method',
      restrictActions:
        restrictedActions['on-paid-plan-but-no-payment-method']
    };
  }
  return alert;
}

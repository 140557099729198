import { useState } from 'react';
import { SubscriptionFrequenciesEnum } from 'src/__apolloGenerated__/graphql';
import Icon from 'src/components/core/atoms/Icon';
import { SubscriptionPlanType } from 'src/components/core/organisms/dialogs/SubscribeDialog';
import SubscribeDialog from 'src/components/core/organisms/dialogs/SubscribeDialog';
import {
  Dialog,
  DialogContent,
  DialogFooter
} from 'src/components/shad-base/dialog';
import { CircleCheckBig } from 'lucide-react';
import {
  Tabs,
  TabsList,
  TabsTrigger
} from 'src/components/shad-base/tabs';
import {
  ROUTES,
  SUBSCRIPTION_PLANS,
  getTailwindTheme
} from 'src/config';
import { getListItemKey } from 'src/utils/format';
import { Button } from 'src/components/shad-base/button';

export default function PaywallDialog({
  open,
  onOpenChange,
  requiredPlan,
  subtitleText
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  requiredPlan: SubscriptionPlanType;
  subtitleText: string;
}) {
  const theme = getTailwindTheme();
  const [billingFrequency, setBillingFrequency] =
    useState<SubscriptionFrequenciesEnum>(
      SubscriptionFrequenciesEnum.Year
    );

  // Open subscribe dialog to collect payment
  const [subscribeDialogOpen, setSubscribeDialogOpen] =
    useState(false);

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="max-w-2xl">
          <div className="flex flex-col flex-nowrap items-center p-md">
            <Icon size="sm" />
            <div className="mt-sm">
              <h6>
                Upgrade to{' '}
                {SUBSCRIPTION_PLANS[requiredPlan?.tier]?.name}
              </h6>
            </div>
            <div className="mt-sm">
              <p className="text-muted">
                {subtitleText ||
                  'Upgrade your plan to use this feature.'}
              </p>
            </div>
          </div>
          <div className="mb-lg">
            <div className="flex flex-col flex-nowrap md:flex-row">
              {/* Top Features */}
              <div className="w-full md:w-1/2">
                <div className="flex flex-col">
                  {requiredPlan?.topFeatures.map((feature, index) => {
                    return (
                      <div
                        key={getListItemKey(index)}
                        className="mb-md"
                      >
                        <div className="flex flex-nowrap items-center">
                          <CircleCheckBig
                            color={theme.colors.primary.DEFAULT}
                          />
                          <p className="ml-md">{feature}</p>
                        </div>
                      </div>
                    );
                  })}
                  <div>
                    <div className="flex flex-nowrap items-center">
                      <CircleCheckBig
                        color={theme.colors.primary.DEFAULT}
                      />
                      <p className="ml-md text-primary">
                        And much more...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Price */}
              <div className="mt-md w-full rounded-lg bg-card p-md md:ml-md md:mt-0 md:w-1/2">
                <div className="flex flex-col items-center justify-between">
                  <p className="mb-md text-primary">
                    {SUBSCRIPTION_PLANS[requiredPlan?.tier]?.name}
                  </p>
                  <>
                    {billingFrequency ===
                      SubscriptionFrequenciesEnum.Year && (
                      <p className="text-primary line-through">
                        ${requiredPlan?.pricePerMonth}/mo
                      </p>
                    )}
                    <h4 className="font-semibold text-primary">
                      $
                      {billingFrequency ===
                      SubscriptionFrequenciesEnum.Month
                        ? requiredPlan?.pricePerMonth
                        : requiredPlan?.pricePerYear}
                      /mo
                    </h4>
                  </>
                  {/* Monthly/Yearly Tabs */}
                  <div className="mt-lg">
                    <Tabs
                      value={billingFrequency}
                      onValueChange={(value) =>
                        setBillingFrequency(
                          value as SubscriptionFrequenciesEnum
                        )
                      }
                    >
                      <TabsList>
                        <TabsTrigger
                          value={SubscriptionFrequenciesEnum.Month}
                        >
                          Monthly
                        </TabsTrigger>
                        <TabsTrigger
                          value={SubscriptionFrequenciesEnum.Year}
                        >
                          Annually (save 10%)
                        </TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DialogFooter>
            <div className="flex w-full justify-end">
              <div className="flex flex-nowrap items-center">
                <Button
                  variant="outline"
                  className="mr-sm"
                  onClick={() =>
                    window.open(ROUTES.RESOURCES.PRICING, '_blank')
                  }
                >
                  Learn More
                </Button>
                <Button
                  onClick={() => {
                    setSubscribeDialogOpen(true);
                    onOpenChange(false);
                  }}
                >
                  Upgrade my plan
                </Button>
              </div>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {/* Subscribe Dialog  */}
      {subscribeDialogOpen && (
        <SubscribeDialog
          type="upgrade"
          open={subscribeDialogOpen}
          onOpenChange={setSubscribeDialogOpen}
          newPlan={requiredPlan}
          initialBillingOption={billingFrequency}
        />
      )}
    </>
  );
}

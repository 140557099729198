import _ from 'lodash';
import { CardPaymentMethodObject } from 'src/__apolloGenerated__/graphql';
import { Button } from 'src/components/shad-base/button';

export default function PaymentMethodCard({
  paymentMethod,
  onEditClick,
  onRemove
}: {
  paymentMethod: CardPaymentMethodObject;
  onEditClick?: () => void;
  onRemove?: () => void;
}) {
  return (
    <div className="flex justify-between rounded-lg border p-lg">
      {/* Credit Card Logo */}
      <div className="flex">
        <div>
          <img
            src={`/static/creditCards/${paymentMethod.brand.toLowerCase()}.svg`}
            width={50}
          />
        </div>
        {/* Brand */}
        <div className="ml-lg mr-xl">
          <div className="flex flex-col flex-nowrap">
            <div>
              <p>
                {_.startCase(paymentMethod.brand)} ending in{' '}
                {paymentMethod.lastFour}
              </p>
            </div>
            <div className="mt-sm">
              <p className="body2 text-muted">
                Expires {paymentMethod.expMonth}/
                {paymentMethod.expYear}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        {onEditClick && (
          <div className="mr-md">
            <Button size="sm" variant="outline" onClick={onEditClick}>
              Edit
            </Button>
          </div>
        )}
        {onRemove && (
          <div>
            <Button
              size="sm"
              variant="destructive"
              onClick={onRemove}
            >
              Remove
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
